import React from 'react';
import cx from "classnames";
const getLocaleString = (num) => parseInt(num).toLocaleString('en-GB')

const Result = (props) => {
    const className = cx(
        "flex flex-col justify-between",
        props.className,
    );

    const containerClass = "text-white my-2 first:mt-0 last:mb-0";
    const headingClass = "bg-denim text-xl px-4 py-2";
    const amountClass = "py-2 px-4 bg-denim-200 text-right text-2xl font-semibold";

    const totalAmountPayable = +props.loanAmount + +props.interestAmount

    return <div className={className} style={{minWidth: '300px'}}>
        <div className={containerClass}>
            <div className={headingClass}>Monthly Payment</div>
            <div className={amountClass}>&#163;{getLocaleString(Math.round(isFinite(props.monthlyEmi) ? props.monthlyEmi : 0))}</div>
        </div>
        <div className={containerClass}>
            <div className={headingClass}>Interest Payable</div>
            <div className={amountClass}>&#163;{getLocaleString(Math.round(isFinite(props.interestAmount)? props.interestAmount : 0))}</div>
        </div>
        <div className={containerClass}>
            <div className={headingClass}>Total Amount Payable</div>
            <div className={`flex ${amountClass} justify-between items-center`}>
                <div className={'text-sm font-normal mr-2'}>(Principal + Interest)</div>
                <div>&#163;{getLocaleString(Math.round(isFinite(totalAmountPayable) ? totalAmountPayable : 0))}</div>
            </div>
        </div>
    </div>
}

export default Result;
