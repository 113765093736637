import React from 'react';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import cx from "classnames";

const MortgageForm = (props) => {
    const className = cx(
        "border-2 border-denim px-6 py-2",
        props.className,
    );

    return <div className={className}>
        <div className={''}>
            <div className={'font-semibold pt-2'}>Loan Amount</div>
            <div className={'lg:flex items-center'}>
                <Slider min={0}
                        max={10000000}
                        marks={{1000: '£1,000', 10000000: '£10,000,000'}}
                        value={props.loanAmount}
                        onChange={props.onLoanAmountChange}/>
                <div className={'lg:ml-4 prefix-label-input mt-10 lg:mt-0'}>
                    <span>&#163;</span><input type="text"
                                              value={props.loanAmount}
                                              min={0}
                                              pattern='([1-9]+[,]?)+'
                                              max={10000000}
                                              onChange={props.onLoanAmountChange}/>
                </div>
            </div>
        </div>
        <div className={'mt-4 lg:mt-8'}>
            <div className={'font-semibold pt-2'}>Interest Rate</div>
            <div className={'lg:flex items-center'}>
                <Slider min={0}
                        max={24}
                        step={0.1}
                        marks={{0: '0%', 24: '24%'}}
                        value={props.interestRate}
                        onChange={props.onInterestRateChange}/>
                <div className={'lg:ml-4 prefix-label-input mt-10 lg:mt-0'}>
                    <span>&#37;</span><input type="number"
                                             value={props.interestRate}
                                             min={0}
                                             max={24}
                                             onChange={props.onInterestRateChange}/>
                </div>
            </div>
        </div>
        <div className={'mt-4 lg:mt-8'}>
            <div className={'font-semibold pt-2'}>Mortgage Term</div>
            <div className={'lg:flex items-center'}>
                <Slider min={0}
                        max={30}
                        step={1}
                        marks={{0: '0 Years', 30: '30 Years'}}
                        value={props.tenure}
                        onChange={props.onTenureChange}/>
                <div className={'lg:ml-4 prefix-label-input mt-10 lg:mt-0'}>
                    <span>Yrs</span><input type="number"
                                           value={props.tenure}
                                           min={0}
                                           max={30}
                                           onChange={props.onTenureChange}/>
                </div>
            </div>
        </div>
    </div>
}

export default MortgageForm;