import React from 'react';
import '../../css/components/_mortgage-calculator.scss';
import MortageForm from './mortgageForm';
import Result from './result';
import EmiBreakup from './emiBreakup';
import AmortizedTableRows from './amortizedTableRows'

const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
]

const getLocaleString = (num) => parseInt(num).toLocaleString('en-GB')

class MortageCalculator extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loanAmount: 100000,
            interestRate: 4,
            tenure: 2,
            showDisclaimer: false,
            showCalculator: false
        }
        this.currentDate = new Date();
        this.currentMonth = this.currentDate && this.currentDate.getMonth();
        this.currentYear = this.currentDate && this.currentDate.getFullYear();
    }

    changeValue = (key, event) => {
        let value;
        if (typeof event === 'object') {
            value = event.target.value;
        } else {
            value = event;
        }
        this.setState({[key]: value});
    }

    getMonthlyEmi = () => {
        const loanAmount = this.state.loanAmount;
        const monthlyInterestRate = this.state.interestRate / 1200;
        const tenureInMonths = this.state.tenure * 12;

        return (
            (loanAmount * monthlyInterestRate * Math.pow(monthlyInterestRate + 1, tenureInMonths)) /
            (Math.pow(monthlyInterestRate + 1, tenureInMonths) - 1)
        )
    }

    onLoanAmountChange = (event) => {
        this.changeValue('loanAmount', event);
    }

    onInterestRateChange = (event) => {
        this.changeValue('interestRate', event);
    }

    onTenureChange = (event) => {
        this.changeValue('tenure', event);
    }


    getRows = () => {
        const loanAmount = this.state.loanAmount;
        const monthlyInterestRate = this.state.interestRate / 1200;
        const tenureInMonths = this.state.tenure * 12;
        const monthlyEmi = this.getMonthlyEmi();
        const rows = [];
        let interestAmount = 0;
        for (let currentRow = 0; currentRow < tenureInMonths; currentRow++) {
            let interest = 0
            let principal = 0
            let balance = 0
            if (currentRow === 0) {
                interest = loanAmount * monthlyInterestRate
                principal = monthlyEmi - interest
                balance = loanAmount - principal
            } else {
                interest = rows[currentRow - 1].balance * monthlyInterestRate
                principal = Math.min(rows[currentRow - 1].balance, monthlyEmi - interest)
                balance = rows[currentRow - 1].balance - principal
            }
            interestAmount += interest
            rows.push({
                interest: interest,
                principal: principal,
                balance: Math.max(0, balance),
                monthlyEmi: monthlyEmi
            })
            if (balance <= 0) {
                return {interestAmount, rows}
            }
        }
        return {interestAmount, rows}
    }

    getMonthRows(currentMonth, currentRow, rows) {
        const monthRows = [];
        for (let currentMonthRow = 0;
             currentRow === 0 ?
                 currentMonthRow < 12 - currentMonth
                 : currentMonthRow < 12 && currentRow + currentMonthRow < rows.length;
             currentMonthRow++
        ) {
            const data = currentRow === 0 ? rows[currentMonthRow] : rows[currentRow + currentMonthRow]
            if (!data) break;

            const {interest, principal, balance, monthlyEmi} = data || {};
            const monthRow = <tr key={`${currentRow} + ${currentMonthRow}`} className={'month-row'}>
                {currentRow === 0 ? (
                    <td>{months[currentMonthRow + currentMonth]}</td>
                ) : (
                    <td>{months[currentMonthRow]}</td>
                )}
                <td>&#163;{getLocaleString(isFinite(monthlyEmi) ? monthlyEmi : 0)}</td>
                <td>&#163;{getLocaleString(Math.round(isFinite(interest) ? interest : 0))}</td>
                <td>&#163;{getLocaleString(Math.round(isFinite(principal) ? principal : 0))}</td>
                <td>&#163;{getLocaleString(Math.round(isFinite(balance) ? balance : 0))}</td>
            </tr>
            monthRows.push(monthRow)
        }
        return monthRows
    }

    getHtmlRows = (rows, currentMonth, currentYear) => {
        const htmlRows = [];
        for (let currentRow = 0; currentRow < rows.length; currentRow++) {
            let yearInterest = 0;
            let yearPrincipal = 0;
            let yearBalance = 0;
            let emi = 0
            if (currentRow === 0) {
                for (let currentMonthRow = 0; currentMonthRow < 12 - currentMonth; currentMonthRow++) {
                    const {interest, principal, balance, monthlyEmi} = rows[currentMonthRow] || {};
                    if (currentMonthRow >= rows.length) {
                        break;
                    }
                    if (currentMonthRow === 12 - currentMonth - 1) {
                        yearBalance = balance;
                    }
                    yearInterest += interest;
                    yearPrincipal += principal;
                    emi = monthlyEmi;
                }
            } else {
                for (let j = 0; j < 12 && currentRow + j < rows.length; j++) {
                    const {interest, principal, balance, monthlyEmi} = rows[currentRow + j]
                    if (j === 11 || currentRow + j === rows.length - 1) {
                        yearBalance = balance;
                    }
                    yearInterest += interest;
                    yearPrincipal += principal;
                    emi = monthlyEmi;
                }
            }
            const monthRows = this.getMonthRows(currentMonth, currentRow, rows)
            htmlRows.push(
                <AmortizedTableRows
                    currentYear={currentYear++}
                    yearPrincipal={Math.round(yearPrincipal)}
                    yearInterest={Math.round(yearInterest)}
                    yearBalance={Math.round(yearBalance)}
                    monthRows={monthRows}
                    monthlyEmi={emi}
                    key={currentYear}
                />
            )
            if (currentRow === 0) {
                currentRow += 12 - currentMonth - 1
            } else {
                currentRow += 11
            }
        }
        return {htmlRows}
    }

    toggleDisclaimer = () => {
        this.setState(({showDisclaimer}) => ({showDisclaimer: !showDisclaimer}))
    }

    render() {
        const {interestAmount, rows} = this.getRows();
        const {htmlRows} = this.getHtmlRows(rows, this.currentMonth, this.currentYear);
        return (
            <section className={"font-arial"}>
                <div className={`lg:p-4 mb-4 lg:max-w-[580px] mx-auto`}>
                    <div className={`flex mortgage-calc-heading w-full justify-between items-end cursor-pointer mortgage-calculator${this.state.showCalculator ? '-close' : '-open'}`} onClick={() => {this.setState({showCalculator: !this.state.showCalculator})}}>
                        <h4 className={"text-lg lg:text-xl text-white lg:uppercase font-semibold mb-0 w-full bg-orange text-center py-2"}>Show Mortgage Calculator</h4>
                        <div className={"calc-icon-container"}>
                            <img src={"/images/icons/calculator.svg"} alt={"calculator"} width={50} height={66}/>
                        </div>
                    </div>
                </div>
                {this.state.showCalculator && <div className={'text-text'} data-datocms-noindex>
                    <div className={'lg:flex'}>
                        <MortageForm {...this.state}
                                     className={"flex-grow-3 lg:mr-2"}
                                     onLoanAmountChange={this.onLoanAmountChange}
                                     onInterestRateChange={this.onInterestRateChange}
                                     onTenureChange={this.onTenureChange}/>
                        <Result
                            className={"flex-grow-1 mt-4 lg:mt-0"}
                            loanAmount={this.state.loanAmount}
                            monthlyEmi={this.getMonthlyEmi()}
                            interestAmount={interestAmount}
                        />
                    </div>
                    <div className={`my-4 ${this.state.showDisclaimer && 'mb-0'}`}>
                        * The Monthly Payment calculation is based on interest rate input provided by the user and for
                        illustrative purposes only. <span className={"text-denim cursor-pointer"}
                                                          onClick={this.toggleDisclaimer}>Show {this.state.showDisclaimer ? 'Less' : 'More'}</span>
                    </div>
                    {this.state.showDisclaimer && <div className={'mb-4'}>
                        The Monthly Payment calculator uses the reducing balance method to calculate loan Montly Payment
                        and total interest payable using key data (Principal, Interest Rate and Tenure)
                        as provided by the user. Additional charges such as processing fees, documentation charges, etc.
                        that may be applicable are not considered by this calculator.
                    </div>}
                    <EmiBreakup htmlRows={htmlRows}/>
                </div>}
            </section>
        )
    }
}

export default MortageCalculator;
