import React, { useState } from 'react';

const getLocaleString = (num) => parseInt(num).toLocaleString('en-GB')

const AmortizedTableRows = ({currentYear, yearPrincipal, yearInterest, yearBalance, monthlyEmi, monthRows }) => {
    const [showRow, toggleShowRow] = useState(false);
    return (
        <>
            <tr className={'yearly-row'}>
                <td>{currentYear} <span className={'arrow-down cursor-pointer w-4 h-4 justify-center items-center inline-flex rounded-full text-denim bg-white'} onClick={() => toggleShowRow((show) => !show)}>{showRow ? '-' : '+'}</span></td>
                <td>&#163;{getLocaleString(isFinite(monthlyEmi)? monthlyEmi : 0)}</td>
                <td>&#163;{getLocaleString(isFinite(yearInterest) ? yearInterest : 0)}</td>
                <td>&#163;{getLocaleString(isFinite(yearPrincipal) ? yearInterest : 0)}</td>
                <td>&#163;{getLocaleString(isFinite(yearBalance) ? yearBalance : 0)}</td>
            </tr>
            {showRow && <>{monthRows}</>}
        </>
    )
}

export default AmortizedTableRows;