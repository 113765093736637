import React from 'react';

const EmiBreakup = ({htmlRows}) => {
    return <div className={"mt-2 overflow-x-scroll w-full emi-table"}>
        <table className={"w-full emi-table"}>
            <thead className={"bg-denim text-white uppercase"}>
            <tr>
                <td style={{minWidth: '145px'}}>Year</td>
                <td>Monthly Payment</td>
                <td>Interest Paid</td>
                <td>Principle Paid</td>
                <td>Outstanding Balance</td>
            </tr>
            </thead>
            <tbody>
            {htmlRows}
            </tbody>
        </table>
    </div>
}

export default EmiBreakup;